.web-build {
  min-height: calc(100vh - var(--header-height, 0px));
  display: flex;
  flex-direction: column;
}

.channel-selector-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
