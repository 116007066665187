.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding-top: 3em;
  padding-bottom: 1em;
  min-height: calc(100vh);
  padding-left: 10%;
  padding-right: 10%;
}

.mobile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding-top: 3em;
  padding-bottom: 5em;
  min-height: calc(100vh);
  padding-left: 5%;
  padding-right: 5%;
}

.main-title {
  font-family: 'Avenir Next Condensed Heavy Italic', sans-serif;
  color: #1A1A1A;
  font-size: 3.5em;
  margin-top: 1em;
  margin-bottom: 0;
  text-align: center;
}

.main-title-mobile {
  font-family: 'Avenir Next Condensed Heavy Italic', sans-serif;
  color: #1A1A1A;
  font-size: 2em;
  margin-top: .5em;
  margin-bottom: 0;
  text-align: center;
}

.subtitle {
  width: 60%;
  font-family: 'Avenir Next Condensed Regular', sans-serif;
  font-size: 2em;
  color: #F6EF4E;
  text-align: center;
  margin-bottom: 1em;
  margin-top: 0;
} 

.subtitle-mobile {
  width: 80%;
  font-family: 'Avenir Next Condensed Regular', sans-serif;
  font-size: 1em;
  color: #F6EF4E;
  text-align: center;
  margin-bottom: .5em;
  margin-top: 0;
} 


.logo {
  width: 20em;
  height: 20em;
}

.logo-mobile {
  width: 10em;
  height: 10em;
}

.screenshot {
  width: 33%; 
  margin: 1em;
}

.screenshot-mobile {
  width: 75%; 
  margin: 2em;
}

.download-row {
  display: flex;
  width: 80%;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 1em;
  margin-bottom: 1em;
}

.download-row-mobile {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: .5em;
  margin-bottom: .5em;
}

.download-button {
  width: 15em;
  margin: 1em;
}

.download-button-mobile {
  width: 10em;
  margin: .5em;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
}

.footer-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-top: 10em;
  padding-bottom: 1em;
}

.footer-column-left {
  display: flex;
  flex-direction: column;
  padding-top: 5em;
  padding-bottom: 5em;
}

.footer-column-right {
  display: flex;
  flex-direction: column;
  padding-top: 5em;
  padding-bottom: 5em;
}

.footer-header-left {
  font-family: 'Avenir Next Condensed Regular', sans-serif;
  font-size: 1.5em;
  text-align: left;
  color: #1C104E;
}

.footer-header-right {
  font-family: 'Avenir Next Condensed Regular', sans-serif;
  font-size: 1.5em;
  text-align: left;
  color: #1C104E;
}

.footer-text-left {
  font-family: 'Avenir Next Condensed Regular', sans-serif;
  font-size: 1em;
  text-align: left;
  color: #1C104E;
  cursor: pointer;
}

.footer-text-right {
  font-family: 'Avenir Next Condensed Regular', sans-serif;
  font-size: 1em;
  text-align: left;
  color: #1C104E;
  cursor: pointer;
}