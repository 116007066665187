.badge-summary {
  display: flex;
  flex-direction: column;
}

.badge-item {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background-color: transparent !important; /* Ensure background is transparent */
}

.badge-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.badge-emoji {
  font-size: 2rem;
}

.badge-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  padding-right: 1rem;
  padding-left: 1rem;
}

.badge-label {
  font-weight: bold;
  font-size: 1rem;
}

.badge-stars {
  font-size: 1rem;
}

.badge-description {
  margin-top: 0.5rem;
  font-size: 1rem;
  text-align: center;
}

.custom-modal .modal-content {
  width: 100%;
  background: linear-gradient(to bottom, var(--gradient-color-1), var(--gradient-color-2));
}


@media (max-width: 768px) {
  .badge-label {
    font-weight: bold;
    font-size: .75rem;
  }
  
  .badge-stars {
    font-size: .75rem;
  }

  .badge-description {
    font-size: .75rem;
  }

  .modal-content {
    max-height: 90vh;
    overflow-y: auto;
  }
}
