.channel-selector-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.channel-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.channel-item {
  flex: 0 0 7em;
  margin: 0 10px;
  padding: 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.channel-item.active {
  font-weight: bold;
  border-bottom: 2px solid #000; /* Highlight the active item */
}