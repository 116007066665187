.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 2em;
  padding-bottom: 2em;
}

.spinner {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.spinner-dot {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.spinner-dot:nth-child(1) {
  top: 6px;
  left: 29px;
  background: #1A1A1A;
  animation-delay: -0.45s;
}

.spinner-dot:nth-child(2) {
  top: 18px;
  left: 18px;
  background: #1A1A1A;
  animation-delay: -0.3s;
}

.spinner-dot:nth-child(3) {
  top: 29px;
  left: 6px;
  background: #1A1A1A;
  animation-delay: -0.15s;
}

.spinner-dot:nth-child(4) {
  top: 40px;
  left: 18px;
  background: #1A1A1A;
  animation-delay: 0s;
}

.spinner-dot:nth-child(5) {
  top: 52px;
  left: 29px;
  background: #1A1A1A;
  animation-delay: 0.15s;
}

.spinner-dot:nth-child(6) {
  top: 40px;
  left: 40px;
  background: #1A1A1A; 
  animation-delay: 0.3s;
}

.spinner-dot:nth-child(7) {
  top: 29px;
  left: 52px;
  background: #1A1A1A;
  animation-delay: 0.45s;
}

.spinner-dot:nth-child(8) {
  top: 18px;
  left: 40px;
  background: #1A1A1A;
  animation-delay: 0.6s;
}

@keyframes spinner {
  0%, 80%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}