/* ALL */
.header {
  background-color: var(--gradient-color-1);
  width: 100%; 
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-logo {
  width: 4em;
  height: 4em;
  cursor: pointer;
}

.header-name {
  height: 4em;
  cursor: pointer;
}

.header-logo-mobile {
  width: 2em;
  height: 2em;
  cursor: pointer;
  margin-top: .5em;
  margin-left: .5em;
}

.home-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.1);
}

.user-info {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 1.5em;
  font-weight: bold;
}

.user-info span {
  margin-right: 10px;
  cursor: pointer;
}