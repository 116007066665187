.card {
  color: #fff;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-mobile {
  color: #fff;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.directions {
  color: #1A1A1A;
  text-align: center;
  margin-top: 10em;
}