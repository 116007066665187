.cmsui-container {
  padding: 2em;
  color: #1A1A1A;
}

.cmsui-content {
  margin-top: 0em;
}

/* Add more specific styles for your CMS UI components here */
