.signin-form {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto;
}

.signin-form input {
  margin-bottom: 10px;
  padding: 8px;
  font-size: 16px;
}

.signin-form button {
  padding: 10px;
  font-size: 16px;
  background-color: #6BAAC9;
  color: white;
  border: none;
  cursor: pointer;
}

.signin-form button:hover {
  background-color: #5999B8;
}