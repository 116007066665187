.cms-landing {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
}

.cms-sidebar {
  width: 240px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.cms-sidebar button {
  width: 100%;
  margin-bottom: 10px;
  padding: 12px 15px;
  text-align: left;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  line-height: 1.3;
  color: #333;
  transition: all 0.3s ease;
  cursor: pointer;
  white-space: normal;
  word-wrap: break-word;
}

.cms-sidebar button:hover {
  background-color: #e0e0e0;
}

.cms-sidebar button.active {
  background-color: #007bff;
  color: #ffffff;
}

.cms-main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.cms-search-bar {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.cms-search-bar input {
  flex-grow: 1;
  margin-right: 10px;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

.cms-search-bar button {
  padding: 10px 15px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cms-search-bar button:hover {
  background-color: #0056b3;
}

.cms-content {
  flex-grow: 1;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.cms-item-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.cms-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.cms-table th {
  background-color: #f2f2f2;
  color: #333;
  font-weight: bold;
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.cms-table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.cms-table tr:hover {
  background-color: #f5f5f5;
}

.cms-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.create-button {
  margin-bottom: 20px;
  padding: 10px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.create-button:hover {
  background-color: #218838;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-actions {
  display: flex;
  gap: 10px;
}

.modal-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.modal-form input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.cancel-button, .save-button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.cancel-button {
  background-color: #f0f0f0;
  color: #333;
}

.save-button {
  background-color: #007bff;
  color: white;
}

.cancel-button:hover {
  background-color: #e0e0e0;
}

.save-button:hover {
  background-color: #0056b3;
}

.color-input {
  display: flex;
  align-items: center;
  gap: 10px;
}

.color-input input[type="text"] {
  flex: 1;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.color-input input[type="color"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
}

.color-input input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

.color-input input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}

.color-input input[type="color"]::-moz-color-swatch {
  border: none;
  border-radius: 50%;
}

.color-preview {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border: 1px solid #ccc;
  vertical-align: middle;
}

.modal-form textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
  min-height: 100px;
}

.modal-form select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
}

.color-selector input[type="text"] {
  width: 80px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.modal-actions .cms-button {
  padding: 8px 16px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 4px;
  margin-left: 10px;
}

.modal-actions .cms-button.cancel {
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ccc;
}

.modal-actions .cms-button.primary {
  background-color: #007bff;
  color: white;
  border: none;
}

.modal-actions .cms-button:hover {
  opacity: 0.9;
}

.modal-actions .cms-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.form-row {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-row label {
  font-size: 0.8em;
  margin-bottom: 5px;
  text-align: left;
}

.form-row input,
.form-row textarea,
.form-row select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
}

.form-row .color-input {
  display: flex;
  align-items: center;
}

.form-row .color-input input[type="text"] {
  flex-grow: 1;
  margin-right: 10px;
}

.form-row .color-input input[type="color"] {
  width: 40px;
  padding: 0;
  height: 40px;
}

.emoji-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.emoji-container input {
  flex-grow: 1;
}

.emoji-container .emoji-preview {
  font-size: 24px;
  min-width: 30px;
}

.emoji-picker-wrapper {
  position: absolute;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 300px;
  padding: 10px;
}

.emoji-picker {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 5px;
  height: 200px;
  overflow-y: auto;
}

.emoji-option {
  cursor: pointer;
  padding: 5px;
  text-align: center;
  font-size: 20px;
}

.emoji-option:hover {
  background-color: #f0f0f0;
}

.emoji-preview {
  font-size: 1.5em;
  cursor: pointer;
  text-align: center;
  margin-right: 10px;
}

.emoji-search {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.no-results {
  grid-column: 1 / -1;
  text-align: center;
  padding: 20px;
  color: #666;
}

.complex-modal-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.complex-modal-form .modal-actions {
  margin-top: 20px;
}

.complex-modal-form .form-row label,
.complex-modal-form .form-field label {
  font-size: 0.8em;
  color: #666;
}

.badges-summary {
  margin-top: 15px;
}

.badge-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.badge-content {
  flex-grow: 1;
}

.badge-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.badge-content li {
  margin-bottom: 5px;
}

.remove-badge-btn {
  background: none;
  border: none;
  color: #ff4d4d;
  cursor: pointer;
  font-size: 18px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;
}

.remove-badge-btn:hover {
  color: #fff;
  background-color: #ff0000;
}

.selection-active-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.selection-active-toggle label {
  margin-right: 10px;
}

/* Ensure the form takes the full width of the screen */
form {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

/* Style the labels */
form label {
  display: block;
  font-size: 0.8em;
  margin-bottom: 5px;
}

/* Style the input fields */
form input[type="text"],
form input[type="email"],
form input[type="password"],
form textarea,
form select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  box-sizing: border-box;
}