.App {
  background: linear-gradient(to bottom, #6BAAC9 80%, #EFEDED 100%);
  min-height: calc(100vh);
  margin: 0;
}

/* @font-face {
  font-family: 'Avenir Next Condensed';
  src: url('fonts/AvenirNextCondensed-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
} */



.input {
  padding: 12px;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  background-color: white;
  margin-top: 5px;
  margin-bottom: 10px;
}

.input:focus {
  outline: none;
}

button {
  padding: 12px;  
  border: none;
  font-weight: bold;
  width: 90px;
  border-radius: 10px;
  background-color: #00B100;
  color: #020202;
  margin-top: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
}

button:hover { 
  color: #020202;
}

button:active {
  color: #020202;
  transform: translateY(2px); /* Slightly move down */
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for depth */
}

.text {
  color: #1A1A1A;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}