.channel-player {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: calc(100vh - var(--header-height, 0px));
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px;
}

.proposition-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  position: relative;
}

.badges-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.meme-text {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  padding: 2rem;
}

.emoji-label {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  background-color: gold;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: #000000;
}

.emoji {
  font-size: 32px;
  padding: 5px;
}

.stars {
  font-size: 16px;
}

.spectrum-value {
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  padding: 1rem;
}

.proposition-controls {
  display: flex;
  justify-content: center;
  width: 100%;
}

.proposition-controls button {
  background-color: transparent;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 24px;
}

.proposition-controls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.proposition-controls span {
  font-size: 16px;
  margin-top: 20px;
}

.badge {
  border-radius: 15px;
  font-size: 12px;
  font-weight: bold;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.horizontal-slider {
  width: 100%;
  height: 30px;
}

.slider-container {
  width: 100%;
  max-width: 300px;
  margin: 20px auto;
}

.slider-value {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  padding-bottom: 10px;
}

.example-thumb {
  cursor: pointer;
  background: #000000;
  border-radius: 100%;
  display: block;
}

.example-thumb.active {
  background-color: #000000;
}

.example-track {
  position: relative;
  background: grey;
  border-radius: 10px;
  height: 4px;
}

.example-track.example-track-0 {
  background: grey;
}

.horizontal-slider .example-track {
  top: 15px;
  height: 10px;
}

.horizontal-slider .example-thumb {
  top: 7px;
  width: 25px;
  height: 25px;
}

.share-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: .8em;
  color: #333;
}

.spectrum-values {
  display: flex;
  justify-content: space-between;
  width: 20%
}

.mode-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
}

.mode-toggle button {
  background-color: #f0f0f0;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8em;
  transition: background-color 0.2s ease-in-out;
}

.mode-toggle button:hover {
  background-color: #e0e0e0;
}

.basic-rating-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.star-rating {
  display: flex;
  justify-content: center;
  gap: 10px;
  grid-column: 2;
}
.star {
  color: gray;
  cursor: pointer;
  transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.3));
  font-size: 4rem;
}

.star:hover {
  transform: scale(1.2);
}

.star.filled {
  color: gold;
  filter: drop-shadow(0 0 4px rgba(255, 215, 0, 0.7));
}

.star-value {
  font-size: 1.2em;
  margin-top: 10px;
}



/* MOBILE VERTICAL */

@media (max-width: 768px) {
  .channel-player {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .proposition-container {
    padding: 10px;
  }

  .badges-container {
    display: flex;
    justify-content: center;
  }

  .slider-container {
    margin-top: 20px;
  }

  .emoji-label {
    font-size: 8px;
  }
  
  .emoji {
    font-size: 24px;
  }

 .star {
    font-size: 3rem;
  }
  
  .stars {
    font-size: 12px;
  }

  .mode-toggle {
    position: static;
    margin-bottom: 10px;
  }

  .star-rating {
    font-size: 2.5em;
  }

  .meme-text {
    padding: 2rem;
    font-size: 20px;
  }

  .spectrum-values {
    width: 80%;
  }
}


/* IPAD VERTICAL */

@media (max-width: 900px) and (min-width: 769px) {
  .spectrum-values {
    width: 50%;
  }
}
